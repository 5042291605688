import "@/styles/index.css"
import {
  Hydrate,
  QueryClient,
  QueryClientProvider
} from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import "allotment/dist/style.css"
import { appWithTranslation } from "next-i18next"
import Head from "next/head"
import { useRouter } from "next/router"
import { ReactNode, useEffect, useMemo, useRef, useState } from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { EditorProvider } from "../contexts/DocumentContext"
import { HeaderProvider } from "../contexts/HeaderContext"
import { SubscriptionProvider } from "../contexts/SubscriptionContext"
import { AuthProvider } from "../contexts/SupabaseAuthContext"
import { TeamProvider } from "../contexts/TeamContext"
import * as ga from "../utils/gtag"

function usePrevious(value: any) {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

// @ts-ignore
function App({ Component, pageProps, router }) {
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            networkMode: "offlineFirst",
            refetchOnWindowFocus: false
          },
          mutations: {
            networkMode: "offlineFirst"
          }
        }
      }),
    []
  )
  const nextRouter = useRouter()
  const previousPathname = usePrevious(router.pathname)

  const getLayout = Component.getLayout ?? ((page: ReactNode) => page)

  // Let's create a state to keep track of whether we're in a browser environment.
  // Initially, we assume that we're on the server.
  const [isClient, setIsClient] = useState(false)
  useEffect(() => {
    // As soon as useEffect runs, we're in the browser.
    setIsClient(true)
    const preferredLanguage = localStorage.getItem("preferredLanguage")
    if (preferredLanguage && preferredLanguage !== router.locale) {
      router.push(router.pathname, router.asPath, { locale: preferredLanguage })
    }
  }, [])

  useEffect(() => {
    if (isClient) {
      const handleRouteChange = (url: string) => {
        ga.pageview(url)
      }

      nextRouter.events.on("routeChangeComplete", handleRouteChange)
      return () => {
        nextRouter.events.off("routeChangeComplete", handleRouteChange)
      }
    }
  }, [nextRouter.events, isClient])

  const component = getLayout(
    <Component {...pageProps} previousPathname={previousPathname} />
  )

  const pageTitle = "GenieSpec"
  const pageDescription =
    "GenieSpec is AI software specification editor with Code Sync. Never outdated specs again."
  const pageImage = "https://geniespec.com/FullLogo.png" // URL to your thumbnail image
  return (
    <div>
      <Head>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href="https://geniespec.com" />

        {/* Open Graph Tags */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={pageImage} />
        <meta property="og:url" content="https://geniespec.com" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={pageImage} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:title" content={pageTitle} />
        {/* Additional meta tags as needed */}
        <link rel="shortcut icon" href="/favicon/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
      </Head>
      <ToastContainer />
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <AuthProvider>
            <SubscriptionProvider>
              <TeamProvider>
                <HeaderProvider>
                  <EditorProvider>
                    {isClient && (
                      <ReactQueryDevtools
                        initialIsOpen={false}
                        position="bottom-left"
                        panelPosition="left"
                      />
                    )}
                    {component}
                  </EditorProvider>
                </HeaderProvider>
              </TeamProvider>
            </SubscriptionProvider>
          </AuthProvider>
        </Hydrate>
      </QueryClientProvider>
    </div>
  )
}

export default appWithTranslation(App)
